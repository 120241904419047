import React from 'react'
import { SortItem } from 'src/components/Sort'
import { TextSubhead } from 'src/composing/CSText'
import { ActionModal } from 'src/scopes/modal/Modals'
import { Radio } from 'src/theme/NewIcons'
import { usePersistedState } from '@commonstock/common/src/utils/usePersistedState'
import { positionsDisplayKey, PositionsDisplayMode } from 'src/scopes/profile/ProfileHooks'

const PostitionsDisplay = () => {
  const [positionsDisplay, setPositionsDisplay] = usePersistedState<PositionsDisplayMode>(
    positionsDisplayKey,
    PositionsDisplayMode.OneDayChange
  )
  const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setPositionsDisplay(
      e.currentTarget.id === 'total-return' ? PositionsDisplayMode.TotalReturn : PositionsDisplayMode.OneDayChange
    )
  }

  return (
    <ActionModal title={'Positions Display Data'} noPad>
      <SortItem onClick={onClick}>
        <TextSubhead>1d change</TextSubhead>
        <Radio active={positionsDisplay === PositionsDisplayMode.OneDayChange} size={1.25} />
      </SortItem>
      <SortItem id="total-return" onClick={onClick}>
        <TextSubhead>Total return</TextSubhead>
        <Radio active={positionsDisplay === PositionsDisplayMode.TotalReturn} size={1.25} />
      </SortItem>
    </ActionModal>
  )
}

export default PostitionsDisplay
